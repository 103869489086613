export default {
    computed: {
        sessionKey() {
            // noinspection BadExpressionStatementJS // Used to trigger recomputation
            this.$store.state.reevaluateLogin;
            return this.$cookies.get("sessionKey");
        }
    },
    methods: {
        destroySession() {
            this.$cookies.remove("sessionKey");
            this.$store.state.reevaluateLogin++;
        },
        setSession(key) {
            this.$cookies.set("sessionKey", key, 0);
        }
    }
}