<template>
  <div class="login-wrapper">
    <div class="login-left">
      <div class="login-left-logo-wrapper-outer">
        <div class="login-left-logo-wrapper" v-html="getSVGImage('evoove_white_withText.svg')"/>
      </div>
      <div class="login-left-subheading">
        <span class="login-left-subheading-accent">User</span> Platform
      </div>
      <span class="login-left-icon" v-html="getSVGImage('evoove_figurative_punch.svg')"/>
    </div>
    <div class="login-right">
      <div class="login-box" v-if="action==='login'">
        <h3 class="login-title">Login</h3>
        <div
            v-if="errorMsg !== null"
            class="login-error"
            v-html="errorMsg"
        />
        <form @submit.prevent="login" id="loginForm">
          <TextInput
              name="email"
              placeholder="E-Mail"
              type="text"
              :form-validate="false"
          />
          <TextInput
              name="pass"
              placeholder="Passwort"
              type="password"
              :form-validate="false"
          />
          <PrimaryButton
              text="Anmelden"
              class="login-button"
          />
        </form>
        <router-link class="pass-reset" to="/reset-password">Passwort vergessen?</router-link>
      </div>
      <div class="login-box" v-if="action==='reset-mail'">
        <h3 class="login-title">Passwort vergessen</h3>
        <div
            v-if="errorMsg !== null"
            class="login-error"
            v-html="errorMsg"
        />
        <div
            v-if="successMsg !== null"
            class="login-success"
            v-html="successMsg"
        />
        <form @submit.prevent="sendResetMail" id="resetMailForm">
          <TextInput
              name="email"
              placeholder="E-Mail"
              type="text"
              :form-validate="false"
          />
          <PrimaryButton
              text="Passwort zurücksetzen"
              class="login-button"
          />
        </form>
        <router-link class="pass-reset" to="/">Zurück</router-link>
      </div>
      <div class="login-box" v-if="action==='input-password'">
        <h3 class="login-title">Passwort zurücksetzen</h3>
        <div
            v-if="errorMsg !== null"
            class="login-error"
            v-html="errorMsg"
        />
        <div
            v-if="successMsg !== null"
            class="login-success"
            v-html="successMsg"
        />
        <form @submit.prevent="resetPassword" id="resetPasswordForm">
          <TextInput
              name="pass1"
              placeholder="Passwort"
              type="password"
              min="10"
              :custom-validate="validatePass"
              @value-changed="val=>pwchanged('pass1',val)"
              ref="pass1"
          />
          <TextInput
              name="pass2"
              placeholder="Passwort wiederholen"
              type="password"
              min="10"
              :custom-validate="validatePass"
              @value-changed="val=>pwchanged('pass2',val)"
              ref="pass2"
          />
          <PrimaryButton
              text="Passwort zurücksetzen"
              class="login-button"
              :disabled="!formValid"
          />
        </form>
        <router-link class="pass-reset" to="/">Zurück</router-link>
      </div>
      <div class="login-footer">
        <p>Ein Service der base26 UG (haftungsbeschränkt)</p>
        <p>v0.1 (beta)</p>
        <p>
          <router-link class="footer-link" to="/reset-password">Impressum</router-link>
          |
          <router-link class="footer-link" to="/reset-password">Datenschutz</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/elements/TextInput.vue";
import PrimaryButton from "@/components/elements/PrimaryButton.vue";

// noinspection JSUnusedGlobalSymbols // $route-watcher is used but not recognized!
export default {
  data() {
    return {
      errorMsg: null,
      successMsg: null,
      action: 'login',
      key: null,
      formValid: false,
      pass1: "",
      pass2: "",
    };
  },
  components: {
    TextInput,
    PrimaryButton,
  },
  mounted() {
    this.$store.commit("setPageTitle", "Login");
  },
  watch: {
    $route: function (val, ignored_oldVal) {
      this.errorMsg = null;
      this.successMsg = null;
      if (typeof val.params.catchAll === "string") {
        if (val.params.catchAll.includes('reset-password/activate')) {
          this.action = 'input-password';
          const full = val.params.catchAll;
          this.key = full.replaceAll("reset-password/activate", "").replaceAll("/", "");
        } else if (val.params.catchAll === 'reset-password') {
          this.action = 'reset-mail';
        } else {
          this.action = 'login';
        }
      } else {
        this.action = 'login';
      }
    }
  },
  methods: {
    validatePass() {
      if (this.pass1.length > 0 && this.pass2.length > 0) {
        return this.pass1 === this.pass2;
      } else {
        return true;
      }
    },
    pwchanged(field, val) {
      if (field === 'pass1') {
        this.pass1 = val;
        if (this.pass1.length > 0 && this.pass2.length > 0) {
          this.$refs.pass2.validate({target: document.getElementById(this.$refs.pass2.fullHTMLId)}, true);
        }
      } else {
        this.pass2 = val;
        if (this.pass1.length > 0 && this.pass2.length > 0) {
          this.$refs.pass1.validate({target: document.getElementById(this.$refs.pass1.fullHTMLId)}, true);
        }
      }
    },
    async login() {
      const data = this.getFormData("loginForm");
      data.type = "user";
      const res = await this.apiRequest("POST", "/account/login", data, false);
      if (res.key) {
        this.errorMsg = null;
        this.setSession(res.key);
        this.$router.push({path: '/'});
        this.$store.state.reevaluateLogin++;
      } else {
        this.errorMsg = "Benutzername oder Passwort falsch!";
        document.getElementById("loginForm").reset();
      }
    },
    async sendResetMail() {
      const data = this.getFormData("resetMailForm");
      data.inputPageUrl = this.frontendUrl + "/reset-password/activate";
      const res = await this.apiRequest("POST", "/account/passwordReset", data, false);
      if (res.success) {
        this.errorMsg = null;
        this.successMsg = "Es wurde ein Link zum Zurücksetzen des Passworts per Mail verschickt."
        document.getElementById("resetMailForm").reset();
      } else {
        this.errorMsg = "Es ist ein unbekannter Fehler aufgetreten.";
        document.getElementById("resetMailForm").reset();
      }
    },
    async resetPassword() {
      const data = this.getFormData("resetPasswordForm");
      data.token = this.key;
      const res = await this.apiRequest("POST",
          "/account/passwordReset/activate", data, false);
      if (res.success) {
        this.errorMsg = null;
        this.successMsg = "Das Passwort wurde erfolgreich zurückgesetzt."
        document.getElementById("resetPasswordForm").reset();
      } else {
        this.successMsg = null;
        console.log(res);
        if (res?.cnt?.id === "passwordsNotEqual") {
          this.errorMsg = "Die beiden Passwörter stimmen nicht überein.";
        } else if (res?.cnt?.id === "wrongParameterConstruction") {
          this.errorMsg = "Bitte geben Sie mindestens 10 Zeichen ein.";
        } else {
          this.errorMsg = "Das Passwort konnte nicht zurückgesetzt werden.";
        }
        document.getElementById("resetPasswordForm").reset();
      }
    },
  },
};
</script>
<style scoped>
.login-wrapper {
    height: 100%;
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.login-left {
    flex: 0.3;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    background-color: red;
    background: linear-gradient(0deg, var(--light-blue) 0, var(--dark-blue) 100%);
    box-shadow: 3px 0 6px rgb(0 0 0 / 16%);
    overflow: hidden;
    position: relative;
}

.login-left-logo-wrapper {
    width: 100%;
    height: auto;
    position: relative;
    padding-left: 10%;
    padding-right: calc(10% - 8px);
    margin-top: 200px;
    box-sizing: border-box;
}

.login-left-logo-wrapper :deep(svg) {
    width: 100%;
    height: 100%;
}

.login-left-logo-wrapper::before {
    content: "";
    background-color: var(--blue);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 8px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}

.login-left-subheading {
    padding-left: 10%;
    padding-right: calc(10% - 8px);
    font-size: 25px;
    color: #fff;
}

.login-left-subheading-accent {
    color: var(--blue);
}

.login-left-icon {
    position: absolute;
    right: 0;
    transform: translateX(40%);
    bottom: 0;
    height: calc(100% - 400px);
    width: 140%;
}

.login-left-icon :deep(svg) {
    height: 100%;
    width: 100%;
}

.login-right {
    flex: 0.7;
    background-image: url(../../assets/img/login_background.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}

.login-right::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url(../../assets/img/login_background_overlay.svg);
    background-size: 80% 100%;
    background-position: 0;
    background-repeat: no-repeat;
}

.login-box {
    z-index: 10;
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translateY(-50%);
    width: 300px;
}

.login-error {
    color: red;
    margin-bottom: 15px;
}

.login-success {
    color: green;
    margin-bottom: 15px;
}

.pass-reset {
    margin-top: 15px;
    display: inline-block;
    color: var(--light-blue);
    font-weight: 500;
}

.login-title {
    margin-top: 0;
    margin-bottom: 15px;
}

.login-footer {
    position: absolute;
    bottom: 32px;
    margin-left: 10%;
    margin-right: 10%;
}

.login-footer p {
    margin: 2px 0;
}

.footer-link {
    color: var(--light-blue);
}

.login-button {
    width: 100%;
    vertical-align: center;
    text-align: center;
}

@media only screen and (max-width: 768px) {
    .login-left-icon {
        height: 100%;
        bottom: unset;
        top: 30px;
        width: unset;
    }

    .login-wrapper {
        flex-direction: column;
    }

    .login-left {
        justify-content: space-between;
        flex-grow: 0;
        flex-shrink: 0;
        padding: 30px;
        align-items: center;
        flex: unset;
    }

    .login-left-logo-wrapper {
        margin-top: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding-left: 0;
        padding-right: 0;
        height: auto;
        box-sizing: content-box;
        align-self: flex-start;
        width: 250px;
        max-width: 100%;
    }

    .login-left-logo-wrapper-outer {
        width: 100%;
        max-width: 300px;
    }

    .login-left-logo-wrapper::before {
        display: none;
    }

    .login-left-subheading {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
        max-width: 300px;
        font-size: 20px;
    }

    .login-right {
        flex-grow: 1;
        flex-shrink: 1;
        background-position-y: 90%;
        background-position-x: 2vw;
        background-size: 150%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        width: 100%;
        padding: 30px;
    }

    .login-box {
        width: 100%;
        max-width: 300px;
        position: static;
        transform: none;
    }

    .login-right::before {
        display: none;
    }

    .login-footer {
        position: relative;
        font-size: 12px;
        width: 100%;
        max-width: 300px;
        bottom: unset;
        margin: 0;
    }
}
</style>