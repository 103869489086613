import {createApp} from 'vue'
import App from './App.vue'
import router from './modules/router'
import store from './modules/store'
import functionsMixin from './modules/mixins/functions'
import configMixin from './modules/mixins/config'
import loginMixin from './modules/mixins/login'
import cookies from 'vue-cookies';
import './assets/css/style.css';
import './assets/css/satoshi/css.css';
import './assets/fa/css/all.min.css';

createApp(App)
    .use(store)
    .use(router)
    .use(cookies)
    .mixin(configMixin)
    .mixin(loginMixin)
    .mixin(functionsMixin)
    .mount('#app')
