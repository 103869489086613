<template>
  <div class="nav-wrapper">
    <div class="logo-wrapper">
      <span class="logo logo-big" v-html="getSVGImage('evoove_white_withoutText.svg')"/>
      <span class="logo logo-small" v-html="getSVGImage('evoove_figurative_white.svg')"/>
    </div>
    <nav>
      <MainMenuLink
          icon="_menu_accounting"
          title="Abrechnung"
          to="/accounting"
          slug="accounting"
      />
      <MainMenuLink
          icon="_menu_charging"
          title="Laden"
          to="/"
          slug="charging"
      />
      <MainMenuLink
          icon="_menu_settings"
          title="Einstellungen"
          to="/settings"
          slug="settings"
      />
    </nav>
  </div>
  <div class="menu-toggle" @click="toggleMenu">
    <span class="menu-toggle-icon" v-html="getSVG('arrow-right-circle')"/>
    <span class="menu-toggle-text">Einklappen</span>
  </div>
</template>
<script>
import MainMenuLink from '@/components/static/_MenuMainLink'

export default {
  components: {
    MainMenuLink,
  },
  methods: {
    toggleMenu() {
      this.$store.state.menuOpen = !this.$store.state.menuOpen;
    }
  },
}
</script>