// noinspection JSUnusedGlobalSymbols // All functions are used!

const CODE_LENGTHS = {
  AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
  CH: 21, CR: 22, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
  FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
  HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
  LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
  MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
  RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,
  AL: 28, BY: 28, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25, SV: 28,
  TL: 23, UA: 29, VA: 22, VG: 24, XK: 20
};

const MONTHS = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember',
]

export default {
  methods: {
    apiRequest: async function (method, endpoint, data = null, sendSession = true, raw = false) {
      const allowedMethods = ['POST', 'GET', 'PATCH', 'PUT', 'DELETE'];
      method = method.toUpperCase();
      if (!allowedMethods.includes(method)) {
        console.error("Forbidden method used!")
      }

      // noinspection JSUnresolvedVariable
      let url = this.apiBaseUrl + endpoint;
      const init = {
        method: method,
        cache: 'no-cache',
        headers: {},
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
      }

      if (sendSession) {
        init.headers.Authorization = 'Bearer UserKey ' + this.sessionKey;
      }

      if (method !== "GET" && method !== "DELETE") {
        init.body = JSON.stringify(data);
        init.headers['Content-Type'] = 'application/json';
      }

      const response = await fetch(url, init);
      const ret = {rawResponse: response, status: response.status, success: false};

      if (response.status === 404) {
        return ret;
      }

      if (response.status === 201 || response.status === 204) {
        ret.success = true;
        return ret;
      }

      if (raw) {
        return response;
      }

      const cnt = await response.json();
      ret.cnt = cnt;

      if ((response.status === 403 && "error" in cnt && (cnt.id === "sessionUnknown" || cnt.id === "sessionExpired"))
          || response.status === 401) {
        this.destroySession();
        return ret;
      }

      if (response.status === 200) {
        if (typeof cnt === "object") {
          cnt.success = true;
        }
        return cnt;
      }

      return ret;
    },
    getFormData: function (id) {
      const form = document.getElementById(id);
      const formData = new FormData(form);
      const data = {};
      formData.forEach((value, key) => {
        if (key in data) {
          if (Array.isArray(data[key])) {
            data[key].push(value);
          } else {
            data[key] = [data[key], value];
          }
        } else {
          data[key] = value;
        }
      });
      return data;
    },
    globalValidate: function (event) {
      let parent = this;
      for (let i = 0; i < 10; i++) {
        if ("formValid" in parent.$data) {
          break;
        }
        if (parent.$parent !== null) {
          parent = parent.$parent;
        } else {
          break;
        }
      }
      if (!"formValid" in parent.$data) {
        console.warn("formValid not found!");
        return;
      }

      let valid = true;

      let form = event.target.form;
      if (form === null) {
        return;
      }
      if (!"elements" in form) {
        console.warn("elements of form not found!");
      }

      let elements = form.elements;
      for (const el of elements) {
        const ds = el.dataset;
        const val = el.value;
        if ("min" in ds) {
          if (val.length < parseInt(ds.min, 10)) {
            valid = false;
          }
        }
        if ("max" in ds) {
          if (val.length > parseInt(ds.max, 10)) {
            valid = false;
          }
        }
        if ("enum" in ds) {
          if (!ds.enum.includes(val)) {
            valid = false;
          }
        }
        if ("notIn" in ds) {
          if (ds.notIn.includes(val)) {
            valid = false;
          }
        }
        if ("hasCustomValidation" in ds && ds.hasCustomValidation === "true") {
          if (!("customValid" in ds) || ds.customValid !== "true") {
            valid = false;
          }
        }
      }

      parent.$data.formValid = valid;
    },
    getSVG(icon) {
      try {
        return require('@/assets/img/svg/icons/' + icon + '.svg');
      } catch (e) {
        console.error("Icon " + icon + " not found!");
      }
    },
    getSVGImage(image) {
      try {
        return require('@/assets/img/svg/' + image);
      } catch (e) {
        console.error("Image " + image + " not found!");
      }
    },
    confirm(text, heading = false) {
      const model = this.$store.state.confirmationOverlay;
      model.heading = heading;
      model.text = text;
      return new Promise((resolve, ignored_reject) => {
        model.resolve = resolve;
        model.visible = true;
      })
    },
    setBreadCrumbs(breadCrumbs) {
      this.$store.state.breadCrumbs = breadCrumbs;
    },
    getItemById(id, data) {
      for (const elKey in data) {
        const el = data[elKey];
        if (el.id === id) {
          return el;
        }
      }
      return {};
    },
    showAPIError(res, model, textInput) {
      // ToDo: showAPIError => Detaillierter?
      console.log(res);

      let text = textInput;
      let type = "error";

      switch (res.status) {
        case 400: // Deutsch? (#77)
          if (res.cnt.id === "wrongParameterConstruction") {
            type = "warning";
            text += " " + res.cnt.msg;
          } else if (res.cnt.id === "missingParameter") {
            text += " Folgende Eigenschaften fehlen: " + res.cnt.additionalInformation.missing.join(", ");
          } else if (res.cnt.id === "wrongParameterType") {
            text += " " + res.cnt.msg;
          } else {
            text += " " + res.cnt.msg;
          }
          break;
        case 403:
          if (res.cnt.id === "passwordWrong") {
            text = "Das angegebene Passwort ist falsch.";
            type = "warning";
          } else {
            text = "Fehler! Sie haben nicht die nötigen Rechte, um diese Aktion durchzuführen.";
          }
          break;
        case 404: // Deutsch? (#77)
          type = "warning";
          text = "Fehler 404: Der angeforderte Inhalt konnte nicht gefunden werden.";
          break;
        case 500:
          text += " Serverfehler! Bitte wenden Sie sich an den Support!";
          model.buttonText = "Support kontaktieren";
          model.buttonAction = () => alert("Support!"); // Support kontaktieren (#78)
          break;
        default:
          text += " " + res.cnt.msg + " Wenden Sie sich an den Support!";
          model.buttonText = "Support kontaktieren";
          model.buttonAction = () => alert("Support!"); // Support kontaktieren (#78)
      }


      model.visible = true;
      model.type = type;
      model.content = text;
    },
    generateRandomString(length = 10) {
      const chars = "abcdefghjkmnpqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ123456789".split('');
      const array = new Uint8Array(length);
      self.crypto.getRandomValues(array);
      let out = "";
      for (const randInt of array) {
        const randIntInValueRange = randInt % chars.length;
        out += chars[randIntInValueRange];
      }
      return out;
    },
    formatIban(country, last4) {
      if (typeof country !== "string") {
        return '';
      }
      const length = CODE_LENGTHS[country.toUpperCase()] - 6; // Length without CountryCode and last4
      const rawIban = country.toUpperCase() + '*'.repeat(length) + last4;
      return (rawIban.match(/.{1,4}/g)).join(' ');
    },
    validateIban(ibanInput) {
      let iban = ibanInput.toUpperCase().replace(/[^A-Z0-9]/g, '');
      iban = iban.replaceAll(" ", "");
      if (iban.length === 0) {
        return false; // IBAN empty
      }
      const parts = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
      if (!parts) {
        return false // IBAN-format wrong
      }
      const country = parts[1];
      const checkNumber = parts[2];
      const remainder = parts[3];
      if (CODE_LENGTHS[country] === undefined || iban.length !== CODE_LENGTHS[country]) {
        return false; // IBAN-length wrong
      }
      const rearranged = remainder + country + checkNumber;
      const digits = rearranged.replace(/[A-Z]/g, (letter) => letter.charCodeAt(0) - 55);
      let fragment;
      let checksum = digits.slice(0, 2);
      for (let offset = 2; offset < digits.length; offset += 7) {
        fragment = String(checksum) + digits.substring(offset, offset + 7);
        checksum = parseInt(fragment, 10) % 97;
      }
      return checksum === 1;
    },
    formatDate(date) {
      return date.toLocaleDateString(undefined, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
    formatSingleDayPeriod(dateStart, dateEnd) {
      let result = dateStart.toLocaleDateString(undefined, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      let startHours = ("" + dateStart.getHours()).padStart(2, "0");
      let startMinutes = ("" + dateStart.getMinutes()).padStart(2, "0");
      let endHours = ("" + dateEnd.getHours()).padStart(2, "0");
      let endMinutes = ("" + dateEnd.getMinutes()).padStart(2, "0");
      result += ', ' + startHours + ":" + startMinutes + " - " + endHours + ":" + endMinutes + " Uhr";
      return result;
    },
    formatPrice(price) {
      const priceString = ("" + price).padStart(3, "0");
      return priceString.substring(0, priceString.length - 2) + "," + priceString.slice(-2) + " €";
    },
    formatFloat(float, decimals = 0) {
      let string = float.toFixed(decimals);
      return string.replaceAll(".", ",")
    },
    getDuration(dateStart, dateEnd) {
      let diff = Math.ceil(Math.abs(dateEnd - dateStart) / 1000);
      return this.formatSeconds(diff);
    },
    formatSeconds(seconds) {
      let result = '';
      let hours = parseInt("" + (seconds / 3600));
      if (hours > 0) {
        result += hours + " Stunde" + (hours > 1 ? 'n' : '');
        seconds -= hours * 3600;
      }
      let minutes = parseInt("" + (seconds / 60));
      if (minutes > 0) {
        if (result !== "") {
          result += " ";
        }
        result += minutes + " Minute" + (minutes > 1 ? 'n' : '');
        seconds -= minutes * 60;
      }
      // if (seconds > 0) {
      //   if (result !== "") {
      //     result += " ";
      //   }
      //   result += seconds + " Sekunde" + (seconds > 1 ? 'n' : '');
      // }
      if (result === "") {
        result = "--- Minuten";
      }
      return result;
    },
    getMonthArray() {
      return MONTHS;
    },
    async loadBlob(endpoint) {
      let pdf = await this.apiRequest(
          "GET",
          endpoint,
          null,
          true,
          true
      );
      if (pdf.status === 200) {
        return URL.createObjectURL(await pdf.blob());
      } else {
        alert("Fehler! Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut!");
        return false;
      }
    },
  },
}