<template>
  <header>
    <div :class="headerLeftClassList">
      <router-link v-if="this.breadCrumbs.length>0"
                   :to="this.breadCrumbs[this.breadCrumbs.length-1].link"
                   class="header-back"
                   v-html="getSVG('arrow-left-s-line')"
      />
      <div class="header-heading-wrapper">
        <h1 class="main-title">{{ pageTitle }}</h1>
      </div>
    </div>
    <div class="header-right">
      <router-link class="header-user-info" to="/settings/account">{{ userName }}</router-link>
      <div class="header-logout">
        <span @click="logout" v-html="getSVG('logout')"></span>
      </div>
    </div>
  </header>
</template>
<script>
import TextInput from "@/components/elements/TextInput";

export default {
  computed: {
    pageTitle() {
      return this.$store.state.pageTitle
    },
    userName() {
      return this.$store.state.userName
    },
    breadCrumbs() {
      return this.$store.state.breadCrumbs;
    },
    headerLeftClassList() {
      let classList = 'header-left';
      if (this.breadCrumbs.length === 0) {
        classList += " noBreadCrumbs";
      }
      return classList;
    }
  },
  components: {
    TextInput,
  },
  methods: {
    search(ignore_event) {
      console.log("search"); // Suchfunktionalität (#68)
    },
    logout() {
      this.apiRequest("GET", "/account/logout").catch(err => console.log(err));
      this.destroySession();
    },
  }
}
</script>