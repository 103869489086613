import {createRouter, createWebHistory} from 'vue-router'

const routes = [
  {
    path: '/accounting',
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "accounting" */ '../components/views/AccountingView.vue'),
      },
      {
        path: 'invoices',
        component: () => import(/* webpackChunkName: "accounting_invoices" */ '../components/views/accounting/ShowInvoices.vue'),
      },
      {
        path: 'history',
        component: () => import(/* webpackChunkName: "accounting_history" */ '../components/views/accounting/ShowHistory.vue'),
      },
    ],
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "charging" */ '../components/views/ChargingView.vue'),
  },
  {
    path: '/settings',
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "settings" */ '../components/views/SettingsView.vue'),
      },
      {
        path: 'account',
        component: () => import(/* webpackChunkName: "settings_account" */ '../components/views/settings/ManageAccount.vue'),
      },
      {
        path: 'invoiceAddress',
        component: () => import(/* webpackChunkName: "settings_invoiceAddress" */ '../components/views/settings/ManageInvoiceAddress.vue'),
      },
      {
        path: 'paymentMethod',
        component: () => import(/* webpackChunkName: "settings_paymentMethod" */ '../components/views/settings/ManagePayment.vue'),
      },
    ],
  },
  {
    path: '/:catchAll(.*)',
    component: () => import(/* webpackChunkName: "notFound" */ '../components/views/NotFound.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
