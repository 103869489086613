<template>
  <div class="button-wrapper">
    <button :class="'button button-primary '+(getClass??'')+' '+(getLoading?'invisible':'')"
            :disabled="disabled"
    >{{ text }}
    </button>
    <span class="button-loading-icon" v-html="getSVGImage('loading_circle.svg')" v-if="loading"/>
  </div>
</template>
<script>
export default {
  props: [
    "text",
    "loading",
    "class",
    "disabled",
  ],
  computed: {
    getLoading() {
      return !!this.loading;
    },
    getClass() {
      return this.class;
    }
  }
}
</script>