<template>
  <div v-if="loggedIn" :class="getClass">
    <ConfirmationOverlay :model="$store.state.confirmationOverlay"/>
    <MainMenu/>
    <div class="outer-wrapper">
      <HeaderBar/>
      <div class="semiFixedFooter-wrapper">
        <div class="scroll-wrapper">
          <div class="wrapper">
            <router-view/>
          </div>
        </div>
        <div class="copyright-wrapper">
          <span class="copyright-left">evoove Management Platform v1.0</span>
          <span class="copyright-right">&copy; base26 UG (haftungsbeschränkt) | Datenschutz / Impressum / AGB</span>
        </div>
      </div>
    </div>
  </div>
  <LoginPage v-else/>
</template>

<script>
import MainMenu from "./components/static/MainMenu.vue"
import HeaderBar from "./components/static/HeaderBar.vue";
import LoginPage from "./components/views/LoginPage";
import ConfirmationOverlay from "@/components/elements/ConfirmationOverlay";

// noinspection JSUnusedGlobalSymbols // $route-watcher is used but not recognized!
export default {
  name: 'App',
  data() {
    return {
      routePath: '',
    };
  },
  watch: {
    $route: function (val, ignored_oldVal) {
      for (const key of Object.keys(this.$store.state.showSubMenus)) {
        this.$store.state.showSubMenus[key] = false;
      }
    }
  },
  components: {
    MainMenu,
    HeaderBar,
    LoginPage,
    ConfirmationOverlay,
  },
  computed: {
    loggedIn() {
      if (typeof this.sessionKey === 'string') {
        this.apiRequest("GET", "/account")
            .then(cnt => {
              this.$store.commit("setUserName", cnt.person_type === "business" ? cnt.last_name : (cnt.first_name + " " + cnt.last_name));
              this.$store.commit("hasPaymentData", cnt.hasPaymentData);
              if (!cnt.hasPaymentData) {
                this.$router.push({path: '/settings/paymentMethod'})
              }
            })
            .catch(err => console.log(err));
        return true;
      }
      return false;
    },
    getClass() {
      let className = 'main-wrapper';

      if (this.$store.state.menuOpen) {
        className += ' menuOpen';
      }

      return className;
    }
  },
}
</script>