<template>
  <div :class="className">
    <input :id="'textInput_'+id"
           v-model="valueModel"
           :data-enum="getEnum"
           :data-max="max"
           :data-min="min"
           :data-not-in="notIn"
           :data-custom-valid="customValidateValid"
           :data-has-custom-validation="this.customValidate !== undefined && typeof this.customValidate === 'function'"
           :name="name"
           class="input input-text"
           placeholder=" "
           :type="getType"
           @focusout="validate"
           @keyup="validate"
           :autocomplete="autocomplete??'on'"
    >
    <label :for="'textInput_'+id">{{ placeholder }}</label>
  </div>
</template>
<script>
import {v4 as uuid4} from "uuid";
import {nextTick} from "vue";

export default {
  data() {
    return {
      valid: true,
      id: null,
      customValidateValid: null,
      valueModel: '',
    }
  },
  props: [
    "name",
    "placeholder",
    "value",
    "class",
    "min",
    "max",
    "enum",
    "notIn",
    "formValidate",
    "type",
    "autocomplete",
    "customValidate",
  ],
  mounted() {
    this.id = uuid4();
    this.fullHTMLId = "textInput_" + this.id;
    this.valueModel = this.value;
  },
  watch: {
    value(val, ignored_oldVal) {
      this.valueModel = val;
    }
  },
  methods: {
    async validate(event, noChange = false) {
      if (!noChange) {
        this.$emit("value-changed", this.valueModel);
      }
      if (this.formValidate !== undefined && this.formValidate !== null && this.formValidate === false) {
        return;
      }

      const el = event.target;
      const value = el.value;
      let valid = true;

      if (this.customValidate !== undefined && typeof this.customValidate === 'function') {
        if (this.customValidate(value)) {
          this.customValidateValid = true;
        } else {
          valid = false;
          this.customValidateValid = false;
        }
      }

      await nextTick();

      this.globalValidate(event);

      if (this.min !== undefined) {
        if (value.length < this.min) {
          valid = false;
        }
      }
      if (this.max !== undefined) {
        if (value.length > this.max) {
          valid = false;
        }
      }
      if (this.enum !== undefined) {
        if (this.enum.includes(value)) {
          valid = false;
        }
      }
      if (this.notIn !== undefined) {
        if (this.notIn.includes(val)) {
          valid = false;
        }
      }
      this.valid = valid;
    }
  },
  computed: {
    className() {
      let className = this.class ?? '';
      if (this.valid !== undefined && !this.valid) {
        className += ' invalid';
      }
      return className + ' input-wrapper';
    },
    getType() {
      if (this.type) {
        return this.type;
      }
      return 'text';
    },
    getEnum() {
      return this.enum;
    },
  }
}
</script>