import {createStore} from 'vuex'

export default createStore({
  state() {
    return {
      pageTitle: "Dashboard",
      userName: "",
      reevaluateLogin: 0,
      confirmationOverlay: {},
      menuOpen: true, // Screen-Size bedenken und Status in Cookie speichern! (#70)
      showSubMenus: {
        objects: false,
        renters: false,
        accounting: false,
        settings: false,
      },
      breadCrumbs: [],
      hasPaymentData: null,
    }
  },
  getters: {},
  mutations: {
    setPageTitle(state, title) {
      state.pageTitle = title;
      document.title = process.env.VUE_APP_TITLE_PREFIX + title + " | evoove User Platform";
    },
    setUserName(state, userName) {
      state.userName = userName;
    },
    hasPaymentData(state, hasPaymentData) {
      state.hasPaymentData = hasPaymentData;
    },
  },
  actions: {},
  modules: {}
})
