<template>
  <div :class="getClass">
    <div class="menu-top-item" @click="clickHandler">
      <span v-html="getSVG(icon)"></span>
      <div class="menu-item-title">{{ title }}</div>
    </div>
    <div v-if="childrenCount>0&&showChildren" class="menu-item-children">
      <router-link v-for="(title,link) in children" :to="link">{{ title }}</router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false,
    };
  },
  props: [
    "nav",
    "href",
    "isActive",
    "isExactActive",
    "title",
    "icon",
    "children",
    "slug",
  ],
  computed: {
    getClass() {
      let classList = "menu-item-wrapper";

      if (this.isActive) {
        classList += " current-menu-parent";
      }
      if (this.isExactActive) {
        classList += " current-menu-item";
      }
      if (this.open) {
        classList += " open";
      }

      return classList;
    },
    childrenCount() {
      return Object.keys(this.children).length;
    },
    showChildren() {
      if (this.isActive) {
        this.$store.state.showSubMenus[this.slug] = true;
      }
      return this.$store.state.showSubMenus[this.slug];
    }
  },
  methods: {
    clickHandler() {
      if (this.$store.state.menuOpen && this.childrenCount > 0) {
        for (const key of Object.keys(this.$store.state.showSubMenus)) {
          if (key === this.slug) {
            continue;
          }
          this.$store.state.showSubMenus[key] = false;
        }
        this.$store.state.showSubMenus[this.slug] = !this.$store.state.showSubMenus[this.slug];
      } else {
        this.nav();
      }
    }
  }
}
</script>