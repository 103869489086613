<template>
  <router-link
      v-slot="{ isActive, isExactActive, href, navigate }"
      :to="to"
      custom
  >
    <MenuMainLinkInner :children="children"
                       :href="href"
                       :icon="icon"
                       :is-active="isActive"
                       :is-exact-active="isExactActive"
                       :nav="navigate"
                       :title="title"
                       :slug="slug"
    />
  </router-link>
</template>

<script>
import MenuMainLinkInner from '@/components/static/_MenuMainLinkInner';

export default {
  components: {
    MenuMainLinkInner
  },

  props: {
    to: String,
    title: String,
    icon: String,
    children: {
      default: {},
      type: Object,
      optional: true,
    },
    slug: String,
  },
}
</script>